import { gql } from "@apollo/client";

const SITES_QUERY = gql`
  query SITES_QUERY($businessUnit : String) {
    sites(businessUnit: $businessUnit) {
      id
      name
      path
      units
      shoeSizeUnits
      image1
      image2
      businessUnit
    }
  }
`

export default SITES_QUERY
