import { useQuery } from "@apollo/client";
import { useEffect } from 'react'

export default function useDynamicQuery(query, options = null) {
  const q = useQuery(query, {
    ...options,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (q && !q.loading) {
      q.refetch()
    }
  }, [JSON.stringify(options)])

  return q
}
