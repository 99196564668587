import graphqlQuery from './graphqlQuery'
import SITES_QUERY from 'graphql/sites.query'
import getCurrentSiteBusinessUnit from './getCurrentSiteBusinessUnit';

const getSites = async () => {
  const sites = await graphqlQuery(SITES_QUERY, 'WP_ROOT', {businessUnit: getCurrentSiteBusinessUnit()})
  if (!sites || !sites.sites) return null

  return sites.sites
}

export default getSites
